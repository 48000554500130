import { Box, Image, Text, Heading, Link, Button, FormControl, FormLabel, Input, FormHelperText, Divider, Alert, AlertIcon, Textarea } from "@chakra-ui/react"
import background from "../../assets/img/bg-image.jpg"
import denuncieImage from "../../assets/img/title-denuncie.png"
import titleCamp from "../../assets/img/title-camp-2.png"
import titleCamp3 from "../../assets/img/title-camp-3.png"
import mobilePhone from "../../assets/img/mobilephone.png"
import FAQComponent from "../../components/FAQ"
import FAQComponentDATA from "./faqComponentData.json"
import LomaFooter from "../../components/LomaFooter"
import styled from "@emotion/styled"
import codigo from "../../assets/pdf/codigo.pdf"
import { useCallback, useRef, useState } from "react"
import jQuery from "jquery"
import logo from "../../assets/img/logo-white.png"

const Form = styled.form``;

export default function Assedio() {

    const [alert, setAlert]: any = useState({
        alert: 'info',
        alertTitle: '',
    })
    const [fileName, setFileName] = useState("");

    const fileFormRef: any = useRef(null);
    const nameFormRef: any = useRef(null);
    const messageFormRef: any = useRef(null);


    function makePostWithAxios(data: any) {
        const API_Link = "https://script.google.com/macros/s/AKfycbyqheRjsKGQYOWgNcy18cWOtWZELcLFGHLyRLC7wouh5hpiqoLPjvu6eiMGSMqNzTqQ/exec";
        jQuery.ajax({
            crossDomain: true,
            url: API_Link,
            method: 'POST',
            data: data,
        })
            .then(() => {
                setAlert({
                    alert: "success",
                    alertTitle: "Sua denuncia foi enviada com sucesso"
                })
            })
            .catch(() => {
                setAlert({
                    alert: "error",
                    alertTitle: "Algum erro aconteceu no site tente novamente mais tarde."
                })
            })
    }

    const postDataToSheets = useCallback((e: any) => {
        e.preventDefault()
        if (fileFormRef !== null && nameFormRef !== null && messageFormRef !== null) {
            setAlert({
                alert: "loading",
                alertTitle: "Estamos carregando a sua soliitação"
            })
            console.log(e);
            const nameFormRefValue = nameFormRef.current.value;
            const messageFormRefValue = messageFormRef.current.value;
            if (fileFormRef.current.files.length !== 0) {

                const fr = new FileReader();
                fr.readAsArrayBuffer(fileFormRef.current.files[0]);
                fr.onload = (f: any) => {

                    const fileInt8: any = new Int8Array(f.target?.result)
                    const fileData = {
                        mimeType: fileFormRef.current.files[0].type,
                        data: JSON.stringify([...fileInt8]),
                        extension: fileFormRef.current.files[0].name.split(".")[fileFormRef.current.files[0].name.split(".").length - 1]
                    }


                    makePostWithAxios({
                        Nome: nameFormRefValue,
                        Mensagem: messageFormRefValue,
                        file: fileData
                    })


                }
            } else {
                makePostWithAxios({
                    Nome: nameFormRefValue,
                    Mensagem: messageFormRefValue,
                })
            }

        }
    }, [fileFormRef, nameFormRef, messageFormRef])

    const fileButtonClick = useCallback(() => {
        if (fileFormRef !== null) {
            fileFormRef.current.click()
        }
    }, [fileFormRef])

    const fileChange = useCallback(() => {
        if (fileFormRef.current.files.length !== 0) {
            setFileName(fileFormRef.current.files[0].name);
        } else setFileName("")
    }, [fileFormRef])

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="center"
            >
                <Alert
                    status={alert.alert}
                    display={alert.alert === "error" || alert.alert === "success" || alert.alert === "loading" ? "flex" : "none"}
                    position="fixed"
                    zIndex="1000"
                    maxW="400px"
                    marginTop={{
                        sm: "5px"
                    }}
                >
                    <AlertIcon />
                    <Text>
                        {alert.alertTitle}
                    </Text>
                </Alert>
            </Box>
            <Box
                width="100%"
                height="95vh"
                display="flex"
                alignItems="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundPosition="75%"
                backgroundImage={`url('${background}')`}
            >
                <Box
                    width="100%"
                    display="grid"
                    justifyContent={{
                        base: "center",
                        sm: "left"
                    }}
                    gridTemplateColumns=""
                    paddingLeft={{
                        smx: "20px"
                    }}
                    alignItems="center"
                >
                    <Box>
                        <Image src={logo} w="100%" maxW="180px"/>
                    </Box>

                    <Box
                        width="100%"
                        maxW="330px"
                        display="grid"
                        backdropFilter={{
                            base: "grayscale(0.2)",
                            sm: "grayscale(0.4)"
                        }}

                    >
                        <Box
                            paddingTop="20px"
                            paddingLeft="20px"
                        >
                            <Heading
                                color="white"
                                w="100%"
                                maxW="240px"
                            >Portal de apoio ao colaborador e prestadores</Heading>
                        </Box>
                        <Box

                            display="flex"
                            flexDirection="column"
                            paddingX="20px"

                        >
                            <Box
                                display="block"
                                w="100%"
                            >
                                <Image src={denuncieImage} width="100%" minW="300px" alt="Canal de Ética" />
                            </Box>
                            <Box
                                color="white"
                                display={{
                                    sm: "flex"
                                }}
                                alignItems="end"
                            >
                                <Box
                                    marginBottom={{
                                        sm: "10px"
                                    }}

                                >
                                    <Text
                                        fontSize="19px"
                                        maxW="240px"
                                    >Nos ajude a manter nosso ambiente de trabalho o mais justo e leve possível.</Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                width="90%"
                paddingTop={{
                    base: "20px",
                    md: "40px"
                }}
                paddingBottom="60px"
                mx="auto"
                display="block"
                alignItems="center"
                height={{
                    md: "550px"
                }}
            >
                <Box
                    width="100%"
                >
                    <Box
                        display={{
                            base: "flex",
                            smx: "block"
                        }}
                        justifyContent="center"
                    >

                        <Box
                            position="relative"
                            z-index="1"
                            width={{
                                base: "80%",
                                mdm: "100%"
                            }}
                            maxW="872px"
                            minHeight="420px"


                            display={{
                                base: "block",
                                mdm: "grid"
                            }}
                            gridTemplateColumns={{
                                base: "none",
                                mdm: "45% 55%"
                            }}
                            height={{
                                md: "420px"
                            }}
                            mx={{
                                base: "auto",
                                mdm: "none"
                            }}

                            top={{
                                base: "6vw",
                                sm: "30px"
                            }}
                        >
                            <Box
                                color="purpleBrand.500"
                                paddingX="10px"
                                textAlign={{
                                    base: "center",
                                    mdm: "end"
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                px="1em"
                                fontSize={{
                                    base: "18px",
                                    mdm: "28px"
                                }}
                                paddingTop={{
                                    base: "10px",
                                    sm: "30px",
                                    md: "40px"
                                }}
                            >
                                <Text>
                                    <Text
                                        fontWeight="black"
                                        width="100%"
                                        as="b"
                                    >SOMOS COMPROMETIDOS COM A ÉTICA</Text> EM TODOS OS NOSSOS NEGÓCIOS E PARCERIAS
                                </Text>
                            </Box>
                            <Box
                                paddingX="40px"
                                paddingY="10px"
                                border="2px solid"
                                borderColor="purpleBrand.500"
                                borderRadius="10px"
                            >

                                <Text
                                    paddingTop="5px"
                                >A Four tem orgulho de atuar com ética, integridade, transparência e respeito às pessoas e exige que esses valores sejam exercitados também por seus funcionários, associados, prestadores de serviços, fornecedores e parceiros.</Text>

                                <Text
                                    paddingTop="5px"
                                >A associação conta com o Canal de Transparência Four, que foi implementado com o objetivo de auxiliar a comunicação de possíveis violações ao Manual de Transparência (código de conduta da Four), com as leis aplicáveis ou políticas e normas da associação.</Text>

                                <Text
                                    paddingTop="5px"
                                >Em algumas localidades, funcionários, terceiros e prestadores de serviços podem fazer uma denúncia, anônima ou não, conforme sua conveniência, diretamente pelo formulário abaixo ou caso se sinta à vontade procure seu gestor direto para mais orientações. </Text>

                                <Text
                                    paddingTop="5px"
                                >Aproveitamos para lembrar que, conforme estabelecido em política corporativa, a Four não tolera retaliações ou punições àqueles que registrarem uma denúncia de boa fé e responsabilidade</Text>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
            <Box
                display={{
                    smx: "flex"
                }}
            >
                <Box
                    background="purpleBrand.500"
                    width="100%"
                    padding="10px"
                    paddingY={{ smx: "50px" }}
                >
                    <Box
                        maxW="400px"
                        width="100%"
                        display="block"
                        marginX="auto"
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <Image src={titleCamp} alt="Saiba quando você deve abrir uma denúncia" />
                        </Box>
                        <Box
                            padding="20px"
                            textColor="white"
                        >
                            Abra uma denúncia em nossa plataforma quando suspeitar ou tiver evidências de violação ao Código de Conduta, descumprimento da legislação ou das normas internas.
                        </Box>
                    </Box>
                </Box>
                <Box
                    background="blackBrand.500"
                    width="100%"
                    padding="10px"
                    paddingY={{ smx: "50px" }}
                >
                    <Box
                        width="100%"
                        display="block"
                        marginX="auto"
                        maxWidth="400px"
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <Image src={titleCamp3} alt="E quando você deve abrir uma reclamação" />
                        </Box>
                        <Box
                            padding="20px"
                            textColor="white"
                        >
                            A reclamação pode ser feita para apresentar uma insatisfação no ambiente profissional que não represente violações ao Código de Conduta, normas ou legislação.
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                paddingX="10px"
                backgroundColor="whiteBrand.500"

            >
                <Box
                    mb="4px"
                    w="100%"
                    maxW={{
                        base: "400px",
                        smx: "600px"
                    }}
                    display="block"
                    marginX="auto"

                >
                    <Box
                        paddingTop={{
                            smx: "70px"
                        }}
                        display={{
                            smx: "flex"
                        }}
                    >
                        <Box>
                            <Box
                                textAlign={{
                                    base: "center",
                                    mdm: "start"
                                }}
                                paddingBottom={{
                                    smx: "10px"
                                }}
                                paddingRight={{
                                    smx: "20px"
                                }}
                            >
                                <Text
                                    paddingTop="10px"
                                    paddingBottom="5px"
                                    lineHeight="8px"
                                >
                                    BAIXE AQUI
                                </Text>
                                <Heading
                                    fontWeight="400"
                                    maxW={{
                                        base: "200px",
                                        mdm: "180px"
                                    }}
                                    display="block"

                                    mx={{
                                        base: "auto",
                                        mdm: "0px"
                                    }}

                                    textAlign={{
                                        base: "center",
                                        mdm: "start"
                                    }}
                                >
                                    Baixe o <Text
                                        fontWeight="black"
                                        as="b"
                                        color="purpleBrand.500"
                                    >CÓDIGO DE CONDUTA</Text>
                                </Heading>
                                <Text
                                    paddingY="10px"
                                >O novo Código de Conduta reflete as transformações da Four e do ambiente de negócios.</Text>
                                <Link
                                    href={codigo}
                                    target="_blank"
                                >
                                    <Button
                                        colorScheme="purpleBrand"
                                        borderRadius="50px"
                                        variant="solid"
                                    >
                                        FAÇA O DOWNLOAD
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box
                            marginTop="10px"
                        >
                            <Image w="100%" maxW="350px" display="block" marginX="auto" src={mobilePhone} alt="Tamanho do celular"></Image>
                        </Box>
                    </Box>
                </Box>

            </Box>
            <Box
                paddingTop="20px"
                paddingBottom="20px"
            >
                <Box
                    paddingX="10px"
                    w="100%"
                    maxW="500px"
                    display="block"
                    marginX="auto"
                >
                    <Box
                        textAlign="center"
                    >
                        <Text
                            lineHeight="8px"
                        >NOSSO FORMULÁRIO</Text>
                        <Heading
                            textColor="purpleBrand.500"
                        >Denuncia?</Heading>
                    </Box>
                    <Form
                        onSubmit={postDataToSheets}
                    >
                        <FormControl
                            padding="10px"
                        >
                            <FormLabel>
                                Nome:
                            </FormLabel>
                            <Input type="text" name="Nome" ref={nameFormRef} autoComplete="off" borderColor="#454545" />
                            <FormHelperText>Esse campo não é obrigatorio.</FormHelperText>
                        </FormControl>
                        <FormControl
                            padding="10px"
                        >
                            <FormLabel>
                                Mensagem:
                            </FormLabel>
                            <Textarea ref={messageFormRef} height="120px" resize="none" required borderColor="#454545" />
                            <FormHelperText>Esse campo é obrigatorio.</FormHelperText>
                        </FormControl>
                        <FormControl
                            padding="10px"
                        >
                            <FormLabel>
                                Fotos ou vídeos que comprovem:
                            </FormLabel>

                            <FormHelperText>Esse campo não é obrigatorio (Suporte somente a 1 arquivo).</FormHelperText>
                            <Button
                                width="100%"
                                colorScheme="purpleBrand"
                                onClick={fileButtonClick}
                            >
                                Escolher Arquivo
                            </Button>
                            <FormHelperText>Arquivo selecionado: {fileName === "" ? "Nenhum Arquivo selecionado" : fileName}</FormHelperText>
                            <Input ref={fileFormRef} onChange={fileChange} type="file" name="uploadfile" display="none" />
                        </FormControl>

                        <Input marginTop="20px" type="submit" value="Enviar Mensagem" color="white" background="purpleBrand.500"></Input>
                    </Form>
                </Box>
            </Box>
            <Divider display={{ smx: "none" }} background="black" height="3px"></Divider>
            <Box
                maxW={{
                    base: "400px",
                    smx: "900px"
                }}
                width="100%"
                display="block"
                marginX="auto"
                paddingX="10px"
            >
                <Box
                    textAlign="center"
                    paddingTop="20px"
                >
                    <Text
                        lineHeight="8px"
                    >NOSSO FAQ</Text>
                    <Heading
                        textColor="purpleBrand.500"
                    >Ainda tem dúvidas?</Heading>
                </Box>
                <Box
                    paddingBottom="20px"
                >
                    <FAQComponent
                        data={FAQComponentDATA}
                    ></FAQComponent>
                </Box>
            </Box>

            <LomaFooter></LomaFooter>

        </Box>
    )
}
