import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools"

const theme = extendTheme({
    breakpoints: {
        sm: "400px",
        smx: "600px",
        mdm: "800px",
        md: "1000px"
    },
    colors: {
        purple: {
            100: '#fff',
            200: '#fff',
            500: '#774291'
        },
        purpleBrand: {
            500: "#5f1ffa"
        },
        redBrand: {
            500: "#ee1d2c"
        },
        whiteBrand: {
            500: "#e9e2e3"
        },
        blackBrand: {
            500: "#29262e"
        }

    },
    variants: {
        smden: {
            fontSize: "15px"
        }
    },
    styles: {
        global: (props:any) => ({
            body: {
                fontSize: {
                    base: "12px",
                    sm: "13px"
                },
                bg: mode("#f0f0f0", "#f0f0f0")
            },
            html: {
                fontSize: "12px",
                bg: mode("#f0f0f0", "#f0f0f0")
            }
        })
    }
})

export default theme
