import { Box, Image, Divider, Text } from "@chakra-ui/react"
import logo from "../../assets/img/logo-white.png"

export default function LomaFooter() {
    return (
        <Box
            color="white"
            width="100%"
            paddingY="30px"
            paddingX="12px"
            marginTop="10px"
            background="#323232"

        >
            <Box
                width="100%"
                maxW="850px"
                display="block"
                marginX="auto"
            >
            <Box
                display="flex"
                paddingBottom="10px"
                flexDirection={{
                    base: "column",
                    sm: "row"
                }}

            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems={{
                        sm: "center"
                    }}
                >
                    <Image
                        src={logo}
                        alt="Logo Four"
                        height="40px"
                    ></Image>
                </Box>
                <Box
                    paddingY={{
                        base: "20px",
                        sm: "0px"
                    }}
                    display="block"
                    marginX="auto"
                    marginRight={{
                        sm: "0px"
                    }}
                    marginBottom={{
                        sm: "20px"
                    }}
                    width={{
                        base: "205px",
                        sm: "165px"
                    }}
                >

                </Box>
            </Box>
            <Box
                width="100%"
                display="block"
                marginX="auto"
            >
                <Divider/>
            </Box>
            <Box
                display={{
                    sm: "flex"
                }}
                justifyContent="space-between"
            >
                <Box>
                    <Box
                        paddingY="20px"
                    >
                        <Text>Rua Coronel Boaventura Mendes Pereira, 293 - Vila Boaventura, Jundiaí - SP, 13201-801</Text>
                    </Box>
                    <Box
                        fontWeight="black"
                    >
                        <Text>Telefone: </Text><Text
                            color="purpleBrand.500"
                        >(11) 4200-0837</Text>
                    </Box>
                </Box>
                <Box
                    paddingY="15px"
                    fontWeight="600"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    maxW="200px"
                >

                </Box>
            </Box>
            </Box>
        </Box>
    )
}
